h1, h2, h3, h4, h5 {
    margin-top: 0;
    margin-bottom: 0;
}

h3, h4 {
    margin-top: 1em;
    margin-bottom: 8px;
}

p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.container {
    max-width: 1000px;
    margin: auto;
    padding: 16px;
}

.intro-container {
    display: flex;
    flex-flow: row wrap;
    column-gap: 16px;
    row-gap: 32px;
    justify-content: center;
}

.intro-logo {
    width: 128px;
    height: 128px;
}

.intro-body {
    flex: 1 none 300px;
}

.projects-container {
    display: flex;
    flex-flow: row wrap;
    column-gap: 8px;
    row-gap: 8px;
}

.project {
    flex: 1 none 350px;
    
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 16px;
    justify-content: center;
    
    position: relative;
    border: 2px solid black;
    border-radius: 8px;
    padding: 8px;
    background-color: white;
}

.project:hover {
    background-color: lightgray;
}

.project > div:nth-child(2) {
    flex: 1 none 250px;
}

.project > img {
    width: 64px;
    height: 64px;
    align-self: center;
}

.project > a {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    text-decoration: none;
    z-index: 10;
    background-color: #FFF;
    opacity: 0;
    filter: alpha(opacity=1);
}

.text-with-icon {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 2px;
}

.margin-top {
    margin-top: 8px;
}

.social-icons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.social-icons a {
    color: inherit;
}
